
.signup-error-message {
    font-size: smaller;
    color: red;
}

.signup-button-disabled {
    pointer-events: none;
    
}
