
.errors-p {
    color: red;
}

.create-group-wrapper {
    padding: 0 3em;
}

.create-group-textarea {
    width: 100%;
    height: 7em;
}

@media screen and (min-width: 769px) {
    .create-group-wrapper {
        width:45%;
    }
}
