/* TODO Add site wide styles */


* {
    font-family: 'Roboto', sans-serif;
    margin: 0 auto;
    /* padding: 0; */

}

.universal-button-red {
    background-color: crimson;
    color: beige;
}

.universal-button-wide {
    height: 3em;
    width: 20em;
}


button {
    background-color: darkgray;
    height: 2em;
    border-radius: 5px;
    box-shadow: 3px 3px black;
    margin: 5px 2px;

    cursor:pointer;
}
