
.create-event-wrapper {
    padding: 0 3em;
}


.create-section-node {
    /* padding: 0.5em 0; */
    /* padding: 1em 0 0.5em; */
    padding: 1em 0 0.5em 0;
    border-top: 0.5px solid lightgray;
}

.create-group-about-input {
    width: 100%;
    height: 7em;

    /* vertical-align: text-top; */

}
