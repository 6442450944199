* {
    box-sizing: content-box;
}

.join-meetup-disabled {
    /* display: none; */
    visibility: hidden;
}

/* .join-meetup-enabled {

} */



.landing-disabled {
    color: lightgray;
    text-decoration: none;
    cursor: default;
    pointer-events: none;
}
/*
.landing-not-disabled {

} */

.landing-page-pointer:hover {
   cursor: pointer;
}


.landing-page-wrapper {
    max-width: 65em;
    min-width: 50em;
    padding: 0 5em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing-page-top {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      "left right";
}

.landing-page-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30em;

}
.landing-page-bottom {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      "lp-bottom-node lp-bottom-node lp-bottom-node"
}


.lp-middle-works {
    margin: 0 auto;
}

.landing-page-wrapper p {
    font-size: smaller;
    /* background-color: blue; */
}

.lp-bottom-left div, .lp-bottom-middle div, .lp-bottom-right div {
    font-size: smaller;
    padding-bottom: .5em;
}
/*  */
.lp-top-left {
    grid-area: left;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.lp-top-left p {
    margin-top: 0;
}


.lp-top-right {
    grid-area: right;
    display: flex;
    justify-content: center;
    align-items: center;
}


.lp-bottom-left, .lp-bottom-middle, .lp-bottom-right {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding: 0 1em;
}



.lp-picture {
    width: 90%
}
