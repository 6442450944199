/* html {

        border: 0px;
        padding:0;
        margin:0;
} */
.dropdown-wrap {
    position: relative;
    /* display: inline-block; */

    right: 130px;
    top: 20px;

    width: 10em;



}


.profile-dropdown {
    background-color: aliceblue;
    position: absolute;
    padding: 0 1em;

    border: solid 1px black;
    border-radius: 10px;
    /* box-sizing: border-box; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profile-dropdown * {
    padding: 0 5px;
}

.party-down-logo {
    width: 15em;
}

.nav-button-light-gray {
    background-color: lightgray;
}

.nav-wrapper {
    border-bottom: 5px solid black;
}

.hidden {
    visibility: hidden;
}

li {
    list-style-type: none
}

.nav-grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      "left . right";
    margin: 0;
}

.nav-grid-left {
    /* background-color: red; */
    grid-area: left;
    display:flex;
    justify-content: center;
    align-items: center;
}

.nav-grid-right {
    /* background-color: lightblue; */
    grid-area: right;
    /* display: flex; */
    justify-content: center;
}
.nav-grid-right-logged-in {
    /* background-color: lightskyblue; */
    grid-area: right;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
}

.nav-grid-right-logged-in a {
    white-space: nowrap;
}

.start-group-navlink {
    text-decoration: none;
}
