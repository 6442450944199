
.group-node-a {
    color: inherit;
    text-decoration: none; /* no underline, link */
}

.group-node {
    /* background-color: blueviolet; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      "group-image group-text group-text";
    width: 30em;
    /* width: 30em; */
    border-top: solid lightgray;

}

.group-node-image {
    grid-area: group-image;
    display: flex;
    justify-content: center;
    align-items: center;
}

.group-node-text {
    grid-area: group-text;
    display: flex;
    flex-direction: column;
    padding: 1em;

    width: 100%;
}

.group-node-text * {
    width: 100%;
    text-align: start;
}



.group-node-text-name {
    font-size: larger;
}
.group-node-text-place {
    font-size: smaller;
    color: lightgray;
}
.group-node-text-about {}
.group-node-text-events-private {
    color: darkgray;
}


.group-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
