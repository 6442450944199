


.event-details-wrapper {
    /* background-color: lightgray; */
}

.event-header {
 /* background-color: hotpink; */
 padding: 1em 2.5em;
}

.event-header-child {
    padding: .5em 0;
}

.event-details-main h2 {
    padding: .5em 0;
}

.event-details-top {
    /* background-color: lightblue; */

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      "event-image group-event-details";
}

.ed-top-link-img {
    grid-area: event-image;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.ed-top-right {
    grid-area: group-event-details;
}

.ed-top-right-grid { /* mini-grids time, price, type */
    margin: .5em 0;
}



.ed-top-right-grids {
    margin: 1em;
    padding: 1em 2em;
    border-radius: 10px;

    background-color: white;
}


.ed-top-right-group {
    /* background-color: blueviolet; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      "group-image group-info";

    column-gap: 1em;
}

.ed-top-right-group-image {
    grid-area: group-image;

    display: flex;
    justify-content: center;
    align-items: center;
}

.ed-top-right-group-info {
    grid-area: group-info;
    display: flex;
    flex-direction: column;
    margin: 0;
}

.ed-top-right-group-info div {
    margin: 0;
}

.ed-top-right-group-name {
    font-size: larger;
}

.ed-top-right-group-pub-priv {
    /* font-size: 0.9em; */
    color: darkgray;
}



.ed-top-right-group-navlink {
    text-decoration: none;
    color: inherit;
}




.ed-top-right-minigrid-time {

    display: grid;
    grid-template-columns: repeat(3, fit-content(100%));
    column-gap: 1em;
    grid-template-rows: auto;
    grid-template-areas:
      "m-clock m-start-label m-start-time"
      "m-clock m-end-label m-end-time";

    /* width: 50%; */
}

.ed-top-right-minigrid-clock-icon {
    grid-area: m-clock;
    width: 2em;
}
.ed-top-right-minigrid-start-label {
    grid-area: m-start-label;
}
.ed-top-right-minigrid-start-time {
    grid-area: m-start-time;
}
.ed-top-right-minigrid-end-label {
    grid-area: m-end-label;
}
.ed-top-right-minigrid-start-time {
    grid-area: m-end-time;
}


.ed-top-right-price-grid {
    display: grid;
    grid-template-columns: repeat(2, fit-content(100%));
    column-gap: 1em;
    grid-template-rows: auto;
    grid-template-areas:
      "p-icon p-value"
      "p-icon p-value";
}
.ed-top-right-price-icon {
    grid-area: p-icon;
    width: 2em;

    display: flex;
    justify-content: center;
}
.ed-top-right-price-value {
    grid-area: p-value;

    display: flex;
    align-items: center;
}
/*
ed-top-right-minigrid-clock-icon
ed-top-right-minigrid-start-label
ed-top-right-minigrid-start-time

ed-top-right-minigrid-end-label
ed-top-right-minigrid-end-time

*/
/* .ed-top-right-event-group {

}
.ed-top-right-event-details {

} */

.event-details-main {
    /* background-color: lightblue; */
    /* width: 100%; */

    margin: 0;
}

.rest-of-page-wrapper {
    display: flex;
    position: absolute;
    /* width: 100%; */

    flex-direction: column;
    background-color: lightgray;

    width: 100%;
    height: 100%;
    padding: 0 3em;

    box-sizing: border-box;
}
