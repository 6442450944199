
.delete-button-red {
    background-color: red;
    color: beige;
}

.delete-button-dark-gray {
    background-color: darkgray;
    color: beige;
}
