
/*  */

.buttons-gray button {
    background-color: darkgray;
    color: beige;
}

.buttons-red button {
    background-color: crimson;
    color: beige;
}

.gd-event-date {
    /* font-size: 1em; */
    color: lightgray;
}

.gd-event-name {
    font-size: larger;
}


.gd-event-node-a {
    color: inherit;
    text-decoration: none; /* no underline, link */
    display: flex;
    /* border-top: solid lightgray; */
    /* border-bottom: solid lightgray */
    margin: 0.3em 0;
    padding: 1em;
    width: 30em;

    background-color: white;
    border-radius: 10px;

    /* justify-items: start; */
}


.gd-event-node {
    /* background-color: blueviolet; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      "event-image event-text event-text";
    width: 100%;

    margin: 0;
    /* border-top: solid lightgray; */
    /* text-align: left; */

}

.gd-event-node-image {
    grid-area: event-image;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.gd-event-node-text {
    grid-area: event-text;
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 1em;
    /*  */
}

.gd-event-node-text * {
    margin: 0;
    /* width: 100%; */
}

.gd-event-node-description {
    grid-area: event-description;
}
/*  */


.gd-middle-organizer {
    font-size: x-large;
    padding: 0.5em 0;
}

.gd-middle-about {
    font-size: x-large;
    padding: 0.5em 0;
}

.gd-node-margin-remove {
    margin: 0;
}

.group-details-event-num {
    font-size: x-large;
    padding: 1em 0;

    margin: 0;

}

.group-details-event-wrapper {
    /* width: 100%;
    background-color: lightgray;
    padding: 0 5em; */

    display: flex;
    /* position: absolute; */
    /* width: 100%; */

    flex-direction: column;
    background-color: lightgray;

    width: 100%;
    height: 100%;
    padding: 0 5em;

    align-items: start;

    box-sizing: border-box;
}

.group-details-middle {
    background-color: lightgray;
    padding: 1em 5em;

    box-sizing: border-box;
}

.group-details-top {
    /* background-color: blueviolet; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
      "group-link-img group-description"
      "group-link-img group-description";
    /* width: 50em; */
    /* border-top: solid lightgray; */
    padding: 1em;
}

.group-details-wrapper {
    padding: 1em;
}

.gd-no-margin {
    margin: 0;
}

.gd-top-link-img {
    grid-area: group-link-img;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gd-top-group-description {
    grid-area: group-description;
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: start; */

    width: 100%;
}

/* .group-details-middle, .group-details-bottom {
    background-color: lightgray;
} */

/* <>
            <div className="group-details-top">
                <div className="gd-top-group-link">
                {"< "}
                <NavLink to="/groups">Groups</NavLink>
                </div>

                <div className="gd-top-image">
                    {groupImageUrl}
                </div>

                <div className="gd-top-group-description"> */
