
.login-error-message {
    font-size: smaller;
    color: red;
}

#modal-content {
    /* background-color: aqua; */
    padding: 1em;
    border-radius: 10px;

    /* display: flex;
    flex-direction: column; */

    /* height: 100%; */
    width: 20em;
}

#modal-content * {
    box-sizing: border-box;
}


#modal-content input {
    width: 100%;
}

#modal-content button {
    /* box-sizing: border-box; */
    width: 100%;
    height: 2em;
    margin: .3em 0;
}
